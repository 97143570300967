import { SignJWT, jwtVerify } from 'jose';



const secretKey = new TextEncoder().encode('SEEECRET');


export const getToken = async (payload) => {
    const token = await new SignJWT(payload)
    .setProtectedHeader({ alg: 'HS256' })
    .sign(secretKey);
    return token;
}   

export const decryptToken = async (token) => {
    return await jwtVerify(token, secretKey)
}

