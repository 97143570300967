import React from 'react';
import styled from 'styled-components';
import { Row } from './defaults';


const ProgressBarWrapper = styled(Row)`
    height: 13px;
    background: rgba(79, 89, 240, 0.4);
    border-radius: 50px;
    overflow: hidden;
    min-width: 300px;

    @media (max-width: 500px) {
        min-width: 230px;
    }
`;

const ProgressBarFilled = styled.div`
    flex: ${props => props.flex};
    background: ${props => !props.warning ? 'linear-gradient(90deg, #4F59F0 0%, #1EDCFF 100%)' : 
    'linear-gradient(90deg, #4F59F0 0%, #1EDCFF 70%, orange 100%)'};
    height: 100%;
    min-width: 20px;
`;

const ProgressBarDot = styled.div`
    width: 6px;
    border-radius: 30px;
    align-self: center;
    transform: translateX(-3px);
    border: 3px solid;
    height: 100%;
    background: white;
    overflow: hidden;
`;

export const StatusProgressBar = ({ warning, percent, total, style }) => {

    return <ProgressBarWrapper style={style}>
        <ProgressBarFilled warning={warning} flex={percent} />
        <ProgressBarDot />
        <div style={{ flex: total - percent }} />
    </ProgressBarWrapper>

}