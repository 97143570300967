import styled from "styled-components"
import { Column, Row } from "./defaults"
import Select from 'react-dropdown-select';
import eyeIcon from '../images/eye.svg';
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateRegistrationValue } from "../redux/reducer/registration";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";
import backArrow from '../images/back-arrow.svg'

const Label = styled.label`
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 0.007em;
    color: ${props => props.error ? 'red' : '#5B637D' } ;
`;

const Wrapper = styled(Column)`
    gap: 7px;
    align-items: stretch;
    min-width: 100px;
`;

const InputBox = styled(Column)`
    height: 56px;
    flex: 1;
    background: #F9F9F9;
    border: 1px solid #DBDBDB;
    border-radius: 8px;
    overflow: ${props => props.isDropdown ? 'initial' : 'hidden'};
    box-sizing: border-box;

    & > div {
        height: 100%;
    }
`;

const Input = styled.input`
    font-family: 'Satoshi-Medium';
    font-size: 16px;
    box-sizing: border-box;
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    color: #051F73;
    background: transparent;
    outline: none;
    border: none;
    flex: 1;
`;

const EnhancedSelect = styled(Select)`

    flex: 1;
    color: #051F73;
    font-weight: 300;


    .react-dropdown-select-input {
        font-size: 16px !important;
        border: none !important;
        outline: none !important;
        color: #051F73;
        padding-left: 3px;
    }

    &.react-dropdown-select {
        outline: none !important;
        border: none !important;
        border-radius: 8px;
        height: 100%;
        padding-right: 15px;
        padding-left: 15px;
        font-weight: 300 !important;
    }
`;

const NoDropdownSelectItem = styled.div`
    padding-top: 13px;
    padding-bottom: 14px;
    ${props => props.noPadding ? '' : `padding-left: 22px;
    padding-right: 22px;` }
    font-size: 16px;
    color: #4F59F0;
    border-radius: 8px;
    text-align: center;
    white-space: nowrap;
    border: ${ props => props.selected ? 
        '1px solid #4F59F0':
        '1px solid #DBDBDB'};
    background: ${ props => props.selected ? 
        'rgba(79, 89, 240, 0.05)':
        'white'};
    cursor: ${props => props.selected ? 'initial' : 'pointer'};
`;

const NoDropdownSelectWrapper = styled.div`
    display: grid;
    gap: 15px;
    margin-top: 8px;
    flex: 1;
`;

const NoDropdownSelect = ({
    options,
    value,
    onChange,
    noPadding,
    optionsGridStyle = {
        gridAutoFlow: 'column'
    }
}) => {

    return <NoDropdownSelectWrapper style={optionsGridStyle}>
        {options.map((option) => <NoDropdownSelectItem 
        noPadding={noPadding}
        selected={value?.value === option.value}
        onClick={() => onChange(option)}>
            {option.label}
        </NoDropdownSelectItem>)}
    </NoDropdownSelectWrapper>

}

const EyeButton = styled.img`
    height: 30px;
    width: 30px;
    padding-right: 10px;
    cursor: pointer;
    opacity: ${ props => props.visible ? 1 : 0.5};
`;

const Suffix = styled.div`
    padding-left: 15px;
    font-size: 16px;
    color: rgba(0,0,0,.5);
`;


const PhoneInputWrapper = (props) => {

    const dispatch = useDispatch();

    const onChange = (e) => dispatch(
        updateRegistrationValue({
            id: props.id, value: typeof e === 'string' ?
            e : e?.target?.value
        })
    )

    const thisError = props.errors[props.id]
    const value = useSelector((state) => state.registration[props.id]);

    return <Column>
    <InputBox style={{ alignItems: 'center'}}>
        <PhoneInput 
        {...props}
        style={{ padding: 20, height: 56, boxSizing: 'border-box', border: 'none' }}
        preferredCountries={['us']}
        country='us'
        value={value}
        containerClass="phoneInput"
        inputClass="phoneInputInput"
        buttonClass="phoneInputButton"
        onChange={onChange} />
    </InputBox>
    {thisError && <Error>{thisError}</Error>}
    </Column>


}

export const PasswordInputWrapper = (props) => {

    const [visible, setVisible] = useState(false);

    const value = useSelector((state) => state.registration[props.id]);
    const dispatch = useDispatch();
    const onChange = (e) => dispatch(
        updateRegistrationValue({
            id: props.id, value: typeof e === 'string' ?
            e : e?.target?.value
        })
    )

    const thisError = props.errors[props.id]
    

    return <Column>
        <Row style={{ flex: 1, alignItems: 'center', boxSizing: 'border-box' }}>
            <InputBox style={{ flexDirection: 'row', alignItems: 'center'}}>
                <Input value={value} onChange={onChange} {...props} type={visible ? 'text' : 'password'} />
                <EyeButton visible={visible} onClick={() => setVisible((v) => !v)} src={eyeIcon} />
            </InputBox>
        </Row>
        {thisError && <Error>{thisError}</Error>}

    </Column>
}

const InputWrapper = (props) => {

    let {
        options, 
        type, 
        noDropdown, 
        optionsGridStyle,
        suffix,
        index,
        placeholder,
        id,
        errors,
        disableTrim,
        numberOnly
    } = props;

    const value = useSelector((state) => state.registration[id]);
    const dispatch = useDispatch();
    const onChange = (e) => {
        let nextValue = (typeof e === 'string' 
            || Array.isArray(e)
            || !!e.value) ?
        e : e?.target?.value;

        if (!disableTrim && typeof nextValue === 'string') {
            nextValue = nextValue.trim()
        }

        if (numberOnly && typeof nextValue === 'string') {
            nextValue = nextValue.replace(/[^0-9.]/g, '')
        }

        dispatch(
        updateRegistrationValue({ id, value: nextValue })
    )

    }

    if (props.type === 'password') return <PasswordInputWrapper {...props} />
    if (props.type === 'phone') return <PhoneInputWrapper {...props} />

    const thisError = errors[id]

    suffix = (suffix?.length && suffix[index]) ? suffix[index] : suffix;
    placeholder = (placeholder?.length && placeholder[index]) ? placeholder[index] : placeholder;
    props = { id, placeholder, options, value, onChange, type, ...props}

    return <Column style={{ flex: 1 }}>
        <Row style={{ flex: 1, alignItems: 'center', boxSizing: 'border-box' }}>
            {(options?.length && noDropdown) && <NoDropdownSelect  optionsGridStyle={optionsGridStyle} {...props} />}
            {!noDropdown && <InputBox isDropdown={options?.length}>
                {!options?.length ? <Input  {...props} placeholder={placeholder} /> :
                <EnhancedSelect values={value ? value : []} onChange={(e) => onChange(e[0])} {...props} />}  
            </InputBox>}
            {suffix && <Suffix>{suffix}</Suffix>}
        </Row>
        {thisError && <Error>{thisError}</Error>}
    </Column>
}

const Error = styled.div`
    color: red;
    font-size: 12px;
    height: 15px;
    margin-top: 5px;
`;

export const RegistrationInput = (props) => {

    const {
        label, 
        gridArea,
        subIds,
        id,
        nestedQuestion,
        errors,
    } = props;

    const value = useSelector((state) => 
        subIds ? subIds.reduce((prev, key) => ({...prev, [key]: state.registration[id]}), {}) :
        state.registration[id]
    );
    
    return <Wrapper style={{ gridArea }}>
        <Label>{label}</Label>
        <Row style={{ gap: 10, boxSizing: 'border-box' }}>
            {subIds ? subIds.map((id, i) => <InputWrapper errors={errors} {...props} key={i} id={id} index={i} />) : 
            <InputWrapper {...props} />}
        </Row>


        {nestedQuestion && 
        nestedQuestion?.condition === value?.value && 
        <div style={{ marginTop: 15 }}>
            <RegistrationInput errors={errors} {...nestedQuestion} />
        </div>}
    </Wrapper>
}

const RegisterTitle = styled.div`
    color: #051F73;
    font-family: 'Satoshi-Bold';
    font-size: 40px;
    font-weight: 700;
    line-height: 51px;
    letter-spacing: -0.013em;
`;

const RegisterSubtitle = styled.div`
    color: #051F73;
    font-family: 'Satoshi-Bold';
    font-size: 18px;
    font-weight: 700;
    line-height: 24.3px;
    letter-spacing: -0.013em;
`;

const RegisterBarWrapper = styled(Row)`

    background: #F1F3F7;
    height: 8px;
    border-radius: 2px;
    overflow: hidden;
    width: 200px;
    
    div {
        background: #F9BF29;
        border-radius: 7px;
        overflow: hidden;
        height: 100%;
    }

`;

export const RegistrationProgressBar = ({ percent, style = {} }) => {

    const flexLeft = percent;
    const flexRight = 100 - percent

    return <RegisterBarWrapper style={style}>
        <div style={{ flex: flexLeft }} />
        <div style={{
            background: 'transparent',
            flex: flexRight
        }}/>
    </RegisterBarWrapper>

}

const GoBackIcon = styled.img`
    height: 12px;
    cursor: pointer;
    margin-right: 10px;
`;

export const RegistrationSectionTitle = ({ children, subtitle }) => {

    const navigate = useNavigate();

    const goBack = () => navigate(-1);

    return <Column style={{ gap: 10 }}>
        <Row style={{ alignItems: 'center' }}>
            <GoBackIcon src={backArrow} onClick={goBack} />
            <RegisterSubtitle>{subtitle}</RegisterSubtitle>
        </Row>
        <RegisterTitle>{children}</RegisterTitle>
    </Column>

}