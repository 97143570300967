import React, { useEffect, useState } from "react";
import styled from 'styled-components'
import { Column } from "../components/defaults";
import { useParams } from "react-router-dom";
import { decryptToken } from "../utils/age";
import logo from '../images/logo-dark.svg'
import { FlexRow, Row } from '../components/defaults';
import arrow from '../images/arrow-navy.svg';
import { AgeInfoFulfilled } from '../components/age-message';


const Wrapper = styled(Column)`

`;

export default function Share() {

    const { token } = useParams();
    const [ data, setData ] = useState(null)
    

    useEffect(() => {
        const getResult = async () => {
            const result = await decryptToken(token);
            setData(result.payload)
        }

        if (token) getResult(token)
    },[token])

    return <Wrapper>
        <TopPanel {...data} />
    </Wrapper>


}

const TopPanelWrapper = styled(FlexRow)`
    background: rgba(10, 19, 48, 1);
    padding-left: 72px;
    padding-right: 72px;
    align-items: center;
    box-sizing: border-box;

    @media (max-width: 1100px) {
        padding-top: 70px;
        padding-bottom: 70px;
        padding-left: 0px;
        padding-right: 0px;
        gap: 50px;
    }

    @media (min-width: 1100px) {
        gap: 150px;
        height: 80vh;
    }
`;

const ButtonsWrapper = styled(FlexRow)`
    margin-top: 40px;
    gap: 15px;

    @media (max-width: 1100px) {
        justify-content: center;
        align-items: center;
    }

`;

const TopPanelText = styled.div`
    color: white;
    font-family: 'Satoshi-Regular';
    font-size: 35px;
    line-height: 40px;
    
    b {
        color: rgba(30, 220, 255, 1)
    }

    @media (max-width: 762px) {
        font-size: 30px;
        line-height: 40px;
    }

    @media (max-width: 1100px) {
        align-items: center;
    }
`;


const TopPanelButton = styled(Row)`
    cursor: pointer;
    white-space: nowrap;
    background: ${props => props.background ? props.background : 'transparent'};
    border: 1px solid white;
    border-radius: 100px;
    padding: 13px 24px;
    color: ${ props => props.color ? props.color : 'white'};
    cursor: pointer;
    align-items: center;

    img {
        margin-left: 10px;
        height: 15px;
    }
`; 

const Divider = styled.div`
    width: 100%;
    height: 1px;
    background: rgba(255,255,255,0.4);

    @media (min-width: 1100px) {
        display: none;
    }
`;


const LeftColumn = styled(Column)`
    flex: 1;


    @media (min-width: 1400px) {
        align-items: flex-end;
        flex: 0.7;
    }

`;

const Header = styled(Row)`
    height: 150px;
    background: rgba(10, 19, 48, 1);
    align-items: center;
    padding: 30px;
    box-sizing: border-box;
    padding-left: 120px;
    padding-top: 10px;
`;

const PreFooter = styled.div`
    height: 200px;
    background: rgba(10, 19, 48, 1);
`;

const Logo = styled.img`
    height: 70px;
`;


const TopPanel = ({ years, months, lower }) => {
    
   

    return <Column>
    <Header>
        <Logo src={logo} />
    </Header>
    <TopPanelWrapper  breakpoint='1100px'>
        <LeftColumn>
            <TopPanelText>My TruMe age is <b>{years} {years === 1 ? 'year' : 'years'}</b></TopPanelText>
            {months != 0 ? <TopPanelText><b>and {months} {months === 1 ? 'month' : 'months'}</b> {lower ? 'lower' : 'higher'} than</TopPanelText> :
            <TopPanelText>{lower ? 'lower' : 'higher'} than</TopPanelText>}
            <TopPanelText>my chronological age.</TopPanelText>
            <ButtonsWrapper>
                <TopPanelButton onClick={() => window.open('https://trumelabs.com/solutions/truage-explorer-test','_blank')} background='white' color='rgba(5, 31, 115, 1)'>Discover yours at trumelabs.com<img src={arrow} /></TopPanelButton>
            </ButtonsWrapper>
        </LeftColumn>
        <Divider />
        <AgeInfoFulfilled hideChronologicalAge />
    </TopPanelWrapper>
    <PreFooter />
    </Column>
}











