import styled from "styled-components"
import { RegistrationForm } from "./template";


let fields = [
    {
        label: 'How would you rate your mental health?',
        id: 'mental_health',
        options: [
            { label: 'Excellent', value: 'Excellent' },
            { label: 'Good', value: 'Good' },
            { label: 'Fair', value: 'Fair'},
            { label: 'Poor', value: 'Poor'}
        ],
        noDropdown: true,
        optionsGridStyle: {
            gridAutoFlow: 'column',
            gridTemplateRows: '1fr 1fr',
            gridTemplateColumns: 'repeat(2, 1fr)'
        }
    },
    {
        label: 'Are you satisfied with your social life?',
        id: 'social_life',
        options: [
            { label: 'Yes', value: 'Yes' },
            { label: 'No', value: 'No' },
            { label: 'Rather Not Say', value: 'Rather Not Say'}
        ],
        noDropdown: true,
        optionsGridStyle: {
            gridAutoFlow: 'column',
            gridTemplateRows: '1fr',
            gridTemplateColumns: 'repeat(2, min-content) 1fr'
        }
    },
    {
        label: `How stressed are you?`,
        id: 'stress',
        options: [
            { label: 'Not at all', value: 'Not at all'},
            { label: 'Somewhat stressed', value: 'Somewhat stressed' },
            { label: 'Really stressed', value: 'Really stressed' },
            { label: 'Extremely stressed', value: 'Extremely stressed' },
        ]
    },

]

const Fields = styled.div`
    display: grid;
    row-gap: 20px;
    column-gap: 20px;
    margin-top: 30px;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    grid-template-areas: 
        "mental_health"
        "social_life"
        "stress"
`;

const requiredFields = [
    'mental_health',
    'social_life',
    'stress'
]

export const RegistrationForm04 = () => {

    return <RegistrationForm
        subtitle='Mental Health'
        title='How have you been feeling lately?'
        Fields={Fields}
        fields={fields}
        progress={70}
        requiredFields={requiredFields}
        nextRoute='/registration/5'
    />
    
}