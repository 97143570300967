import React, { useRef, useState } from 'react';
import { LinkTertiary, Container, Input, Row } from '../components/defaults';
import mainbg from '../images/main-bg.svg';
import sphere from '../images/sphere.svg';
import topCurve from '../images/top-curve.svg';
import { getAuth, signInWithEmailAndPassword, browserLocalPersistence, setPersistence } from 'firebase/auth';
import { AuthHeader } from '../components/auth-header';
import { 
    Contents, 
    ErrorComponent, 
    FormBox, 
    FormContents, 
    MainBg, 
    Sphere, 
    SubmitButton, 
    ThisTitle, 
    TopCurve 
} from '../components/auth-defaults';
import api from '../utils/api';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';

export const SignIn = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const pwRef = useRef();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const barcode = searchParams.get('redirect_kit');

    const registerKit = () => {
        NotificationManager.success("You're logged in!")
        setTimeout(() => navigate(`/kit/number?force=${barcode}`), 100)
    }

    const submit = async () => {
        if (loading || !email || !password) return;
        setLoading(true);
        setError('');
        
        try {
            await setPersistence(getAuth(), browserLocalPersistence);
            await signInWithEmailAndPassword(getAuth(), email, password);
            if (barcode) await registerKit();
        } catch (err) {
            try {
                await (await api.get(`/legacy?value=${email}`)).data;
                navigate('/sign-in/reset-pw-instructions');
            } catch (err) {
                //console.log('not an user', err);
            }

            if (err.message === 'Firebase: Error (auth/user-not-found).') setError('There was an error, please try another email or password.')
            else if (err.message === 'INVALID_LOGIN_CREDENTIALS') setError('There was an error, please try another email or password.')
            else setError('There was an error, please try again or contact support.')
        }
        setLoading(false);
    }

    return <Container style={{ background: '#4F59F0', minHeight: '80vh' }}>
        <AuthHeader dark={true} />
        <Contents> 
        <FormBox>
                    <FormContents>
                        <ThisTitle>Login to your account</ThisTitle>
                        <Input 
                            nextRef={pwRef}
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)} 
                            label='Email' 
                            type='email'
                            style={{ marginTop: 32, marginBottom: 32}} />
                        <Input 
                            value={password} 
                            type='password'
                            ref={pwRef}
                            submit={submit}
                            onChange={(e) => setPassword(e.target.value)} 
                            label='Password'  />
                        <div style={{ flex: 1 }} />
                        <ErrorComponent>{error}</ErrorComponent>
                        <Row style={{ marginTop: 50, alignItems: 'center' }}>
                            <SubmitButton loading={loading} disabled={loading || !email || !password} onClick={submit}>Submit</SubmitButton>
                            <LinkTertiary to='/sign-in/recover-pw'>Forgot Password?</LinkTertiary>
                        </Row>
                    </FormContents>
                    <TopCurve src={topCurve}/>
                </FormBox>
            
            <MainBg src={mainbg}/>
            <Sphere src={sphere}/> 
        </Contents>
        
    </Container>    
}