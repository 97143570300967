import { Column } from "./components/defaults"
import { RegistrationForm01 } from "./pages/full-kit-registration/01"
import { RegistrationForm02 } from "./pages/full-kit-registration/02"
import { RegistrationForm03 } from "./pages/full-kit-registration/03"
import { RegistrationForm04 } from "./pages/full-kit-registration/04"
import { RegistrationForm05 } from "./pages/full-kit-registration/05"
import { RegistrationForm06 } from "./pages/full-kit-registration/06"


export const Viewer = () => {

    return <Column style={{ padding: 40 }}>
        <RegistrationForm06/>
    </Column>

}
