import styled from "styled-components"
import { RegistrationForm } from "./template";
import { useSelector } from "react-redux";
import api from "../../utils/api";
import { useNavigate } from "react-router-dom";
import useAuth from "../../utils/useAuth";

const yesNoOptions = {
    options: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
        
    ],
    noDropdown: true,
    optionsGridStyle: {
        gridAutoFlow: 'column',
        gridTemplateRows: '1fr',
        gridTemplateColumns: 'repeat(2, min-content)'
    }
}

let fields = [
    {
        label: 'Do you drink coffee?',
        id: 'coffee_consumption',
        ...yesNoOptions
    },
    {
        label: 'Do you smoke?',
        id: 'tobacco_consumption',
        ...yesNoOptions
    },
    {
        label: 'Do you wake up rested?',
        id: 'waking_up_condition',
        ...yesNoOptions
    },
    {
        label: 'Do you have trouble sleeping?',
        id: 'trouble_sleeping',
        ...yesNoOptions
    },
    {
        label: 'How many alcoholic drinks do you have per week?',
        id: 'alcohol_consumption',
        options: [
            { label: '0', value: '0' },
            { label: '1-2', value: '1-2' },
            { label: '3-5', value: '3-5'},
            { label: '6-9', value: '6-9'},
            { label: '10+', value: '10+'}
        ],
        noDropdown: true,
        optionsGridStyle: {
            gridAutoFlow: 'column',
            gridTemplateRows: '1fr',
            gridTemplateColumns: '1fr'
        }
    },
    {
        label: 'On average, how many hours do you sleep per night?',
        id: 'sleep_hours',
        options: [
            { label: '5 or less', value: '5' },
            { label: '6', value: '6' },
            { label: '7', value: '7'},
            { label: '8', value: '8'},
            { label: '9', value: '9'},
            { label: '10 or more', value: '10+'},
        ],
        noDropdown: true,
        optionsGridStyle: {
            gridAutoFlow: 'column',
            gridTemplateRows: '1fr 1fr',
            gridTemplateColumns: 'repeat(3, 1fr)'
        }
    },
    {
        label: 'On average, how many days per week do you engage in physical activity?',
        id: 'physical_activity_days',
        options: [
            { label: '0', value: '0' },
            { label: '1', value: '1' },
            { label: '2', value: '2'},
            { label: '3', value: '3'},
            { label: '4', value: '4'},
            { label: '5', value: '5'},
            { label: '6+', value: '6+'},
        ],
        noDropdown: true,
        noPadding: true,
        optionsGridStyle: {
            gridAutoFlow: 'column',
            gridTemplateRows: '1fr',
            gridTemplateColumns: 'repeat(7, 1fr)'
        }
    },
]

const Fields = styled.div`
    display: grid;
    row-gap: 20px;
    column-gap: 20px;
    margin-top: 30px;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    grid-template-areas: 
        "coffee_consumption"
        "tobacco_consumption"
        "waking_up_condition"
        "trouble_sleeping"
        "alcohol_consumption"
        "sleep_hours"
        "physical_activity_days"
`;

const requiredFields = [
    "coffee_consumption",
    "tobacco_consumption",
    "waking_up_condition",
    "trouble_sleeping",
    "alcohol_consumption",
    "sleep_hours",
    "physical_activity_days",
]



const clearData = (values) => {
    return Object.entries(values).reduce((prev, [key, value]) => {
        if (Array.isArray(value)) return {...prev, [key]: value[0].value}
        if (value?.value) return {...prev, [key]: value?.value}
        return {...prev, [key]: value}
    }, {})
}

export const RegistrationForm06 = () => {

    const values = useSelector((state) => state.registration);
    const navigate = useNavigate();
    const { currentUser } = useAuth();

    const submit = async () => {
        try {
            const data = clearData(values);
            const link = !!currentUser ? '/kit/register' : '/auth/register';
            const result = await api.post(link, data);
            
            if (result.data?.age_results_ready) navigate('/registration/success?age_available=' + result.data?.age_results_ready)
            else if (result.data?.created_report) navigate('/registration/success?report_available=' + result.data?.created_report)
            else navigate('/registration/success')
        } catch (err) {
            throw new Error(err.message);
        }
    }

    return <RegistrationForm
        subtitle='Habits & Lifestyle'
        title='We’re almost at the finish line!'
        Fields={Fields}
        fields={fields}
        progress={95}
        buttonCta="Submit"
        nextRoute='/registration/success'
        requiredFields={requiredFields}
        submitAction={submit}
    />
    
}