import styled from "styled-components"
import { COUNTRIES } from "../../files/countries";
import { RegistrationForm } from "./template";


const ETHNICITY_OPTIONS = ['White', 'Hispanic and Latino', 'Black / African American', 'Asian', 'American Indian or Alaska Native', 'Native Hawaiian or Other Pacific Islander', 'Other']


let fields = [
    {
        label: `What's your date of birth?`,
        id: 'date_of_birth',
        type: 'date'
    },
    {
        label: `What's your biological sex?`,
        id: 'biological_sex',
        options: [
            { label: 'Female', value: 'Female' },
            { label: 'Male', value: 'Male' },
            { label: 'Other', value: 'Other' }
        ]
    },
    {
        label: `What gender do you identify with?`,
        id: 'gender',
        options: [
            { label: 'Female', value: 'Female' },
            { label: 'Male', value: 'Male' },
            { label: 'Other', value: 'Other' }
        ]
    },
    {
        label: 'What country are you located in?',
        id: 'country',
        options: COUNTRIES.map(({ label }) => ({ value: label, label }))
    },
    {
        label: 'What is your ethnicity?',
        id: 'ethnicity',
        options: ETHNICITY_OPTIONS.map((value) => ({ value, label: value }))
    }
]

const Fields = styled.div`
    display: grid;
    row-gap: 20px;
    column-gap: 20px;
    margin-top: 30px;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    grid-template-areas: 
        "date_of_birth"
        "biological_sex"
        "gender"
        "country"
        "ethnicity"
`;

const requiredFields = [
    'date_of_birth',
    'biological_sex',
    'gender',
    'country',
    'ethnicity',
]

export const RegistrationForm02 = () => {

    return <RegistrationForm
        subtitle='Personal Details'
        title='Please provide the following information:'
        Fields={Fields}
        fields={fields}
        progress={32}
        requiredFields={requiredFields}
        nextRoute='/registration/3'

    />
    
}