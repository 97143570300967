import React from 'react';
import { StatusProgressBar } from './status-progress-bar';
import styled from 'styled-components';
import search from '../images/search.svg';
import alertIcon from '../images/alert-white.svg';
import { FlexRow, Column, Row } from './defaults';

const BREAKPOINT = 762

const Wrapper = styled(Row)`
    background: rgba(10, 18, 42, 1);
    padding: 20px;
    box-sizing: border-box;
    align-items: center;

    @media (max-width: ${BREAKPOINT}px) {
        align-items: initial;
        gap: 10px;
        height: initial;
    }
`;


const IconWrapper = styled(Column)`
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background: #4F59F0;
    border-radius: 8px;
`;

const Icon = styled.img`
    width: 25px;
`;


const Title = styled.div`
    font-size: 18px;
    color: white;
    flex: 1;
`;

const KitWrapper = styled(Row)`
    height: 60px;
    border-bottom: ${props => props.last ? 'initial' : '1px solid rgba(255,255,255,.1)'};
    align-items: center;

    @media (max-width: ${BREAKPOINT}px) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }
`;      


const TopTitle = styled.div`
    color: white;
    opacity: 0.7;
    font-size: 14px;

    @media (max-width: ${BREAKPOINT}px) {
        font-size: 12px;
    }
`;

const PendingKits = styled(Column)`
    gap: 10px;
    flex: 1;
    margin-left: 40px;
`;

const AlertIcon = styled.img`
    height: 15px;
    width: 15px;
    margin-right: 10px;
`;

const TitleColumn = styled(Column)`

    margin-bottom: 10px;
    justify-self: center;

    @media (min-width: ${BREAKPOINT}px) { 
        flex: 1;
        margin-bottom: 0px;
    }

`;

const KitStatus = ({ status, barcode, test_types, last }) => {
                
    const testType = test_types[0]?.name;

    const testName = testType === 'Folate' ? 'DNA Methylation Genetic' : 'TruMe Age'

    const step = status === "PENDING" ? 0 :
        status === "UNASSIGNED" ? 0 :
        status === "REGISTERED" ? 0 : 
        status === "FLAGGED" ? 3 :
        status === "IN_LAB" ? 3 : 4

    const title = status  === "REGISTERED" ? 'Please send back your kit.' : 
        status === "FLAGGED" ? "We had an delay processing your kit." :
        status === "IN_LAB" ? "We’re sequencing your DNA." : ""
    

    return <KitWrapper last={last}>
        <TitleColumn>
            <TopTitle>{testName} Test {barcode}</TopTitle>
            <Title>{title}</Title>
        </TitleColumn>
        
        {status === 'FLAGGED' && <AlertIcon src={alertIcon} />}
        <StatusProgressBar warning={status === 'FLAGGED'} percent={step} total={5} />

    </KitWrapper>
}

export const StatusBar = ({ pendingKits }) => {

    return <Wrapper>
        
        <IconWrapper>
            <Icon src={search} />
        </IconWrapper>
        <PendingKits>
        {pendingKits.map((kit, i) => <KitStatus last={i === pendingKits.length - 1} {...kit} />)}
        </PendingKits>


    </Wrapper>

}