import React from "react";
import styled from "styled-components";

const Mask = styled.div`
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100vw;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0,0.8);
    z-index: 100;
`;


export const Modal = ({ children, onClickOutside }) => {

    const onClickMask = (e) => {
        e.stopPropagation();
        if (onClickOutside) onClickOutside();
    }

    return <Mask onClick={onClickMask}>
        {children}
    </Mask>

}