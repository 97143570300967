import React from 'react';
import styled from 'styled-components';
import { Column, Row } from './defaults';
import logoDark from '../images/logo-dark.svg';
import logoWhite from '../images/logo-white.svg';
import { House } from '../images/house.js';
import { UserRibbon } from './user-ribbon.js';
import { Body } from '../images/body-icon.js';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Logo = styled.img`
    height: 56px;
    margin: 12px 24px;

    @media (max-width: 768px) {
        height: 43px;
        margin: 14px 0px;
    }

    margin-left: 0px;
    

`;  

const Wrapper = styled(Row)`
    border-bottom: ${props => props.dark ? '1px solid #5963FD' : '1px solid #E0E0E0'};
    align-items: center;
    background: ${props => props.dark ? '#4F59F0' : 'white'};
    padding-right: 24px;
    padding-left: 24px;

    @media (max-width: 768px) {
        padding-right: 20px;
        padding-left: 20px;
    }
`;

const Menu = styled(Row)`
    gap: 32px;
    align-self: stretch;
    align-items: center;
`;

const LinkBox = styled.a`
    text-decoration: none;
    display: flex;
    flex-direction: Column;
    height: 100%; 
    border-bottom: ${props => props.selected ? '2px solid #4F59F0' : '2px solid transparent'};   
`;

const LinkWrapper = styled(Row)`
    background: ${props => props.selected ? '#F5F6FA' : 'white'};
    color: ${props => props.selected ? '#4F59F0' : '#5B637D'};
    font-size: 18px;
    padding: 13px 16px;
    align-items: center;
    cursor: pointer;
    border-radius: 8px;
    position: relative;
`;

const Text = styled.div`
    margin-left: 6px;
    color: ${props => props.selected ? '#4F59F0' : '#5B637D'};

    @media (max-width: 762px) {
        display: none;
        margin-left: 0px;
    }
`;

const Link = ({Icon = House, children, href, ...props}) => {

    const location = useLocation();
    const selected = location.pathname === href;

    return <LinkBox href={href} selected={selected}>
        <div style={{ flex: 1 }} />
        <LinkWrapper {...props} selected={selected}>
            <Icon fill={selected ? '#4F59F0' : '#5B637D'} />
            <Text selected={selected}>{children}</Text>
        </LinkWrapper>
        <div style={{ flex: 1 }} />
    </LinkBox>
}



export const DashboardHeader = ({ dark = false }) => {

    const ageData = useSelector((s) => s.user.userData.health_results)?.length > 0;


    return <Wrapper dark={dark}>
        <div style={{ flex: 1 }}>
        <Logo src={dark ? logoDark : logoWhite}/>
        </div>
        <Menu>
            <Link href='/dashboard' Icon={House} selected>Home</Link>
            {ageData && <Link href='/dashboard/age' Icon={Body}>Biological Age</Link>}
        </Menu>
        <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
            <UserRibbon />
        </div>

    </Wrapper>

}