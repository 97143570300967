import React from 'react';
import styled from 'styled-components';
import teach from '../images/teach.svg';
import calendar from '../images/calendar.svg';
import { BannerButton } from './banner-button';
import content1 from '../images/content-1.png';
import content2 from '../images/content-2.png';
import { Column } from './defaults';
import { useSelector } from 'react-redux';
import moment from 'moment';

const Text = styled.div`
    color: #051F73;
    font-size: 24px;

    @media (max-width: 762px) {
        font-size: 24px;
    }
`;

const Background = styled.img`
    position: absolute;
    z-index: 1;
    top: 0px;
    left: 0px;
    height: 400%;
    width: 100%;
    margin-top: -600px;
    object-fit: cover;
    
    @media (max-width: 762px) {
        margin-top: initial;
        height: 100%;
        width: 100%;
    }
`;

const Banner = styled(Column)`
    position: relative;
    border-radius: 32px;
    overflow: hidden;
    padding: 40px;

    @media (max-width: 762px) {
        border-radius: 20px;
        padding: 30px;
    }  
`;

const Contents = styled(Column)`
    z-index: 2;
    flex: 1;
`;

const TextContent1 = styled(Text)`
    width: 70%;
    font-size: 20px;

    @media (max-width: 762px) {
        width: 100%;
    }
`;



export const Content1 = ({}) => {
    return <Banner style={{ gridArea: 'content1' }}>
        <Contents>
            <TextContent1 style={{ width: '70%'}}>Numerous elements shape your aging process, and our goal is to guide you in understanding them.</TextContent1>
            <div style={{ flex: 1 }} />
            <BannerButton innerTextMobile='Learn More' innerText='' icon={teach} outerText='Discover what shaped your aging journey' />
        </Contents>
        <Background src={content1}/>
    </Banner>
}


const TextContent2 = styled(Text)`
    z-index: 3;
    width: 50%;

    @media (max-width: 762px) {
        width: initial;
    }
`;

const Background2 = styled.img`
    height: 150%;
    left: initial;
    object-fit: contain;
    top: -100px;
    z-index: 0;
    right: -200px;
    position: absolute;

    @media (max-width: 762px) {
        position: relative;
        height: 300px;
        width: 300px;
        top: initial;
        right: initial;
        align-self: center;
    }
`;

export const Content2 = () => {

    const pending_test = useSelector(state => state.user.userData?.kits)?.find(k => k?.status === 'PROCESSED');
    const health_results = useSelector(state => state.user.userData.health_results);
    const lastResult = health_results?.length > 0 && health_results[health_results?.length - 1];
    const lastDate = lastResult?.date ? new Date(lastResult?.date) : null;
    const remainingDays = lastDate ? moment(lastDate)?.add(120, 'days').diff(moment(), 'days') : null



    return <Banner style={{ gridArea: 'content2', background: 'linear-gradient(102.88deg, #F9F6F0 0%, #F9E5B9 100%)' }}>
        <Contents>
            <TextContent2>For best age results, we recommend completing 2 or more tests 4-6 weeks apart.</TextContent2>
            <Background2  src={content2}/>
            <div style={{ flex: 1 }} />
            {(!!remainingDays && !pending_test) && <BannerButton 
            innerTextMobile='Learn More' blackText icon={calendar} 
            outerText={remainingDays > 0 ? `${remainingDays} days until you should test again` : `Get tested now!`} />}
        </Contents>
    </Banner>
}
