/* eslint-disable no-restricted-globals */
import React, { useEffect } from 'react';
import { Column, FlexRow, Row } from '../components/defaults';
import styled from 'styled-components';
import arrow from '../images/arrow-navy.svg';
import welcomeCurve from '../images/welcome-curve.svg';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserData } from '../redux/reducer/user';
import loadingIcon from '../images/grid.svg';
import { Folate } from '../components/folate-panel';
import { StatusBar } from '../components/status-bar';
import { AgeBanner } from '../components/age-banner';
import { Content1, Content2 } from '../components/banners';
import useAuth from '../utils/useAuth';
import registerIcon from '../images/register.svg';
import api from '../utils/api';
import { Modal } from '../components/modal';
import { BarcodeInputBox } from './kit-registration/number-input';
import { useState } from 'react';

const Wrapper = styled(Column)`
    background: #FDFDFF;
`;  

const Grid = styled.div`
    display: grid;
    grid-template-rows: 150px 308px 308px 1fr;
    grid-template-columns: 1fr 1fr;
    max-width: 1200px;
    gap: 20px;
    margin: 30px;
    align-self: center;
    grid-template-areas:
        'welcome welcome'
        'agebanner content1'
        'agebanner content2'
        'folate folate';

    @media (max-width: 1202px) {
        margin: 20px;
        gap: 20px;
        grid-template-rows: 150px 670px 338px 338px 1fr;
        grid-template-columns: 1fr;

        grid-template-areas:
            'welcome'
            'agebanner'
            'content1'
            'content2'
            'folate'
        ;
    }

    @media (max-width: 762px) {
        grid-template-rows: 280px 650px 608px 608px 1fr;
    }
`;

const WelcomeBox = styled(Column)`
    position: relative;
    border-radius: 32px;
    overflow: hidden;
    grid-area: welcome;

    @media (max-width: 762px) {
        border-radius: 15px;
    }
`;

const WelcomeCurve = styled.img`
    width: 150%;
    height: 100%;
    object-fit: cover;
`;

const WelcomeBoxContents = styled(FlexRow)`
    position: absolute;
    top: 25px;
    left: 50px;
    right: 50px;
    align-items: center;

    @media (max-width: 762px) {
        bottom: initial;
        left: 30px;
        bottom: 30px;
        align-items: flex-start;
    }

`;

const WelcomeText = styled(Column)`
    
    flex: 1;

    
`;

const Header = styled.div`
    color: white;
    font-size: 48px;
    line-height: 50px;
    font-family: 'Satoshi-Medium';
    margin-bottom: 0px;
    white-space: nowrap;

    @media (max-width: 762px) {
        font-size: 35px;
        line-height: 40px;
    }  
`;

const UserWelcomeText = styled(Header)`
    background: -webkit-linear-gradient(102.69deg, #F9BF29 3.41%, #FFF6DF 98.2%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;




const Welcome = ({setRegisterKitModalVisible}) => {

    const user = useSelector(state => state.user)
    
    return <WelcomeBox>
        <WelcomeBoxContents>
            <WelcomeText>
                <Header>Welcome Back,</Header>
                <UserWelcomeText>{user?.userData?.first_name}!</UserWelcomeText>
            </WelcomeText>
            <RegisterButton onClick={() => setRegisterKitModalVisible(true)}>Register a New Kit</RegisterButton>
        </WelcomeBoxContents>
        
        <WelcomeCurve src={welcomeCurve} />
    </WelcomeBox>
}

const LoadingScreenWrapper = styled(Column)`
    align-items: center;
    justify-content: center;
    height: 80vh;
`;

const LoadingIcon = styled.img`
    height: 40px;
`;

const ButtonWrapper = styled(Row)`
    background: rgba(0, 0, 0, 0.08);
    border: 1px solid rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(20px);
    border-radius: 106px;
    height: 60px;
    align-items: center;
    padding-right: 10px;
    padding-left: 20px;
    color: white;
    font-size: 18px;
    cursor: pointer;
    white-space: nowrap;
`;

const Icon = styled.img`
    height: 20px;
    width: 20px;
    margin-right: 10px;
`;

const Dot = styled(Column)`
    height: 45px;
    width: 45px;
    border-radius: 100px;
    background: white;
    margin-left: 20px;
    align-items: center;
    justify-content: center;

    img {
        height: 15px;
        width: 15px;
    }
`;

const RegisterButton = ({children, ...props}) => {

    return <ButtonWrapper {...props}>
        <Icon src={registerIcon} />
        {children}
        <Dot>
            <img src={arrow} alt='open'/>
        </Dot>
    </ButtonWrapper>
}

const LoadingScreen = () => {
    return <LoadingScreenWrapper>
        <LoadingIcon src={loadingIcon} />
    </LoadingScreenWrapper>
}


const RegisterKitModal = ({ setModalVisible, onRegisterKit }) => {

    return <Modal onClickOutside={() => setModalVisible(false)}>
        <BarcodeInputBox onClickGoToDashboard={onRegisterKit} onClick={e => e.stopPropagation()}/>
    </Modal>

}

export const Dashboard = () => {

    const dispatch = useDispatch();
    const [registerKitModalVisible, setRegisterKitModalVisible] = useState(false);
    const { currentUser, isLoading } = useAuth();
    const { loading, userData } = useSelector((state) => state.user);
    //const dataAvailable = !loading && Object.keys(userData).length > 0

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (currentUser) {
            dispatch(fetchUserData())
        };
    }, [currentUser])

    const healthResults = userData.health_results;
    const pendingKits = userData?.kits?.filter((kit) => kit.status !== 'PROCESSED');
    
    const onRegisterKit = () => {
        dispatch(fetchUserData())
        setRegisterKitModalVisible(false)
    }

    return <Wrapper>
        {registerKitModalVisible && <RegisterKitModal onRegisterKit={onRegisterKit} setModalVisible={setRegisterKitModalVisible}/>}
        {loading || isLoading ? <LoadingScreen /> : <>
        {pendingKits?.length > 0 && <StatusBar pendingKits={pendingKits} />}
        <Grid>    
            <Welcome setRegisterKitModalVisible={setRegisterKitModalVisible} />
            <AgeBanner ageData={healthResults}/>
            <Content1 />
            <Content2 />
            <Folate />
        </Grid>
        </>}
    </Wrapper>
}