import React, { useRef, useEffect, useState } from 'react';
import moment from 'moment';
import { 
    select, 
    scaleTime, 
    scaleLinear, 
    axisBottom, 
    axisLeft, 
    line, 
    curveMonotoneX,
    min,
    max,
    selectAll
} from 'd3';
import { Row } from './defaults';
import { useSelector } from 'react-redux';

const mockData = [
    { date: new Date('2023-01-01'), value: 0 },
    { date: new Date('2023-02-01'), value: 0 },
    { date: new Date('2023-03-01'), value: 0 },
    { date: new Date('2023-04-01'), value: 0 },
    { date: new Date('2023-05-01'), value: 0 },
    { date: new Date('2023-06-01'), value: 0 },
    { date: new Date('2023-07-01'), value: 0 },
];


const LineGraph = ({ 
    data = mockData, 
    upperColor = '#F929BF', 
    lowerColor = '#1EDCFF',
    textColor = 'white',
    middleValue = `40y`,
    suffix = 'y',
    midCentered = true, 
    focusPoint ,
    forceDomain,
}) => {

    let refData = data
    const graphContainer = useRef(null);
    const axisContainer = useRef(null);
    const [windowSize, setWindowSize] = useState(0)

    useEffect(() => {
        const listener = window.addEventListener('resize', (e) => setWindowSize((v) => v + 1))
        return () => window.removeEventListener('resize', listener);

    }, [])


    useEffect(() => {
        if (data && graphContainer.current && axisContainer.current) {
            const xSpacing = 100;
            const sidePadding = 50
            const numberOfMonths = moment(moment()).diff(moment(refData[0].date), 'months');
            let width = Math.max((numberOfMonths * xSpacing) + (sidePadding * 2));
            
            let height = 360;
            let contentHeight = height - 70;
            let graph = select(graphContainer.current);
            let fixedAxis = select(axisContainer.current);
            let expectedGraphWidth = graphContainer.current.getBoundingClientRect().width;

            fixedAxis.selectAll('*').remove();
            graph.selectAll("*").remove();

            let axisWrapper = fixedAxis.append('svg')
            .attr('width', 40)
            .attr('height', height)
            .append('g')
            .append('g')
            .attr('width', '100%')
            .attr('height', height)
            .attr('transform', `translate(10, 30)`)
            
            graph = graph.append('svg')
            .attr('width', Math.max(expectedGraphWidth, width))
            .attr('height', height)

            const upperClipId = "upper-clip-path";
            const lowerClipId = "lower-clip-path";
            const values = refData.map((d) => Math.abs(d.value));
            console.log(values)
            let lower = values.length === 1 ? values[0] - 6 : Math.min(...refData.map((d) => Math.abs(d.value)));
            let upper = values.length === 1 ? values[0] + 6 : Math.max(...refData.map((d) => Math.abs(d.value)));
            
            if (midCentered) {
                let yDomain;
                yDomain = Math.max(upper, lower);
                yDomain = yDomain + 0.2 * yDomain;
                lower = -yDomain;
                upper = yDomain;
            } 
            
            
            if (lower === upper) {
                lower = midCentered ? -5 : 0;
                upper = midCentered ? 5 : 5;
            }
            //console.log(refData)
            let domain = (forceDomain || [lower, upper])

            let svg = graph.append('g')
            .attr('width', '100%')
            .attr('height', height)
            .attr('transform', `translate(0, 30)`)

            const x = scaleTime()
            .domain([min(refData, d => d.date), moment().add(1, 'day').toDate()])
            .range([sidePadding, width - sidePadding]);

            const y = scaleLinear()
            .domain(domain)
            .range([contentHeight, 0]);

            if (focusPoint) {
                svg.append('rect')
                .attr('x', x(new Date(focusPoint)) - xSpacing/2)
                .attr('width', xSpacing)
                .attr('y',y(upper) + 5)
                .attr('height', y(lower) - 10)
                .attr('rx', 8)
                .attr('ry', 8)
                .attr('fill', 'rgba(79, 89, 240, 1)')
            }

            graph.append("defs").append("clipPath")
            .attr("id", upperClipId)
            .append("rect")
            .attr("width", '100%')
            .attr("height", y(0));

            graph.append("defs").append("clipPath")
            .attr("id", lowerClipId)
            .append("rect")
            .attr("width", '100%')
            .attr("height", '100%')
            .attr('transform', `translate(0, ${y(0)})`);
            

            const xTicks = [];
            for (let i = 0; i < numberOfMonths + 1; i++) {
                xTicks.push(moment(refData[0].date).add(i, 'month').toDate())
            }

            
            const xAxis = axisBottom(x)
            .tickSize(0)
            .tickPadding(20)
            .tickValues(xTicks)
            .tickFormat((v, index) => {
                return `${moment(v).format('MMM')} ‘${moment(v).format('YY')}`
            })
            

            svg.append('g')
            .attr('transform', `translate(0,${contentHeight})`)
            .call(xAxis)
            .call(g => g.select(".domain").remove())
            .selectAll('text')
            .style('font-size', '14px')
            .style('font-family', 'Satoshi-Medium')
            .style('fill', textColor);
        
            
            if (midCentered) {
                const yPos = y(0);
                svg.append('line') 
                .attr('x1', 0)  
                .attr('y1', yPos)
                .attr('x2', '100%')
                .attr('y2', yPos)
                .attr('stroke', textColor)
                .attr('strokeWidth', 1);
            }

        
            svg.append('line') 
                .attr('x1', 0)  
                .attr('y1', y(lower))
                .attr('x2', '100%')
                .attr('y2', y(lower))
                .attr('stroke', 'rgba(219, 219, 219, 1)')
                .attr('strokeWidth', 1);

            
                svg.append('line') 
                .attr('x1', 0)  
                .attr('y1', y(upper))
                .attr('x2', '100%')
                .attr('y2', y(upper))
                .attr('stroke', 'rgba(219, 219, 219, 1)')
                .attr('strokeWidth', 1);

            let ticks = []
            
            for (let tick = lower; 
                tick < Math.abs(upper) + 0.3;
                tick += (Math.abs(upper) * 2) / 4) {
                ticks.push(tick.toFixed(0))
            }
            

            const yAxis = axisLeft(y)
            .tickSize(0)
            .tickPadding(-30)
            .tickValues(ticks)
            .tickFormat((v, index) => {
                if (index === 2 && middleValue) return middleValue
                return `${v > 0 ? '+' : ''}${v}${suffix}`
            })
            
            axisWrapper.append('g')
            .call(yAxis)
            .call(g => g.select('.domain').remove())
            .call(g => g.selectAll('.tick line').remove())
            .selectAll('text')
            .style('font-size', '14px')
            .style('font-family', 'Satoshi-Medium')
            .style('fill', textColor);

            svg.append('path')
            .datum(data)
            .attr('fill', 'none')
            .attr('stroke', upperColor)
            .attr('strokeWidth', 3)
            .attr('d', line()
                .x(d => x(d.date))
                .y(d => y(d.value))
                .curve(curveMonotoneX)
            )
            .attr("clip-path", `url(#${upperClipId})`);

            svg.append('path')
            .datum(data)
            .attr('fill', 'none')
            .attr('stroke', lowerColor)
            .attr('strokeWidth', 3)
            .attr('d', line()
                .x(d => x(d.date))
                .y(d => y(d.value))
                .curve(curveMonotoneX)
            )
            .attr("clip-path", `url(#${lowerClipId})`);

            var tooltip = selectAll("#graph-tooltip");
            
            svg.selectAll(".dot")
            svg.selectAll(".dot")
            .data(data)
            .enter().append("circle")
            .attr("class", "dot")
            .attr("cx", d => x(d.date))
            .attr("cy", d => y(d.value) - 0)
            .attr("r", 6)
            .attr("fill", "#ffffff")
            .attr("stroke", "#F9BF29")
            .attr("strokeWidth", 2.5)

            svg.selectAll(".dot2")
            .data(data)
            .enter().append("circle")
            .attr("class", "dot")
            .attr("cx", d => x(d.date))
            .attr("cy", d => y(d.value) - 0)
            .attr("r", 10)
            .attr("fill", "transparent")
            .attr("stroke", "transparent")
            .attr("strokeWidth", 2.5)
            .style("z-index", 10000)
            .on("mouseover", function(event, d) {
                const scrollTop = document.documentElement.scrollTop;
                const {x, y} = event.target.getBoundingClientRect();
                tooltip
                .style("left", (x - 47) + "px")
                .style("top", (y + scrollTop - 140) + "px")
                .style("transform", "translateY(30px)")
                .style("display", "initial")
                .style("pointer-events", "none")

               
                const [years, months] = d.value.toFixed(1).split('.')

                tooltip.select('#date').text(moment(d.date).format(`MMM 'YY`))
                tooltip.select('#value').text(d.biologicalAge?.toFixed(1));
                tooltip.select('#diff').text(`${d.value > 0 ? '+' : ''}${years}y ${months}m`);

            })
            .on("mouseout", () => {
                tooltip.style("display", "none");
            });

            svg.append('g')
            .attr('class', 'grid')
            .call(axisLeft(y).tickSize(-Math.max(expectedGraphWidth, width)).tickFormat('').tickValues(ticks))
            .call(g => g.select('.domain').remove()) 
            .call(g => g.selectAll('.tick line') 
                .attr('stroke', 'rgba(219, 219, 219, 0.3)'));

            
            if (expectedGraphWidth < width) {
                if (focusPoint) {
                    //console.log(focusPoint)
                    graphContainer?.current?.scrollTo(x(focusPoint) - expectedGraphWidth + 50, 0)
                } else {
                    graphContainer?.current?.scrollTo(x(data[data.length - 1].date) - expectedGraphWidth + 50, 0)
                }
            } 
        }
    }, [data , windowSize]); // Depend on data prop

    return (<Row
    style={{ 
        display: 'flex', 
        width: '100%', 
        position: 'relative', 
        paddingRight: 50, 
    }}>
        <Row style={{ flex: 1, overflowX: 'hidden',}}>
            <div style={{
                height: 380,
                flex: 1,
                overflowX: 'scroll'
            }} className="d3-component" ref={graphContainer} />
            <div style={{ width: 50 }} />
        </Row>
        <div style={{
            height: 360,
            width: 40,
            right: 60,
            top: 0,
            position: 'absolute',
        }} className="d3-component" ref={axisContainer} />
    </Row>
    );
};

export const AgeGraph = ({ ...props }) => {
    
    const data = useSelector((s) => s.user.userData.health_results);

    const formattedData = [...data].reverse().map((d) => ({
        value: (d.biological_age - d.chronological_age),
        biologicalAge: d.biological_age,
        date: new Date(d.registered_at)
    }))

    const middleValue = data[data.length - 1].chronological_age;

    return <LineGraph data={formattedData} middleValue={`${middleValue.toFixed(0)}yo`} {...props} />

}


export default LineGraph;