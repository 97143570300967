import React from 'react';
import styled from 'styled-components';
import ageBgEllipse from '../images/gradient-flare.svg';
import { Column, Row } from './defaults';
import clockIcon from '../images/white-clock.svg';
import calendar from '../images/calendar-white.svg';
import progressGoodDown from '../images/progress-good-down.svg';
import progressBadUp from '../images/progress-bad-up.svg';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { FinalAge } from './final-age';
import { useEffect } from 'react';

const AgeBgEllipse = styled.img`
    height: 100%;
    
    @media (min-width: 1100px) {
        max-width: 40vw;
    }
`;

const Contents = styled(Column)`
    align-items: center;
    justify-content: space-between;
    padding: 40px;
    z-index: 10;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    flex: 1;
    height: 100%;
    box-sizing: border-box;


`;


const AgeText = styled.div`
    background: -webkit-linear-gradient(180deg, #FFFFFF 17.8%, #CED3FF 86.6%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Satoshi-Regular';
`;

const AgeInteger = styled(AgeText)`
    font-size: 180px;
    line-height: 160px;
    height: 160px;
    margin-right: 5px;

    @media (max-width: 762px) {
        font-size: 120px;
        line-height: 90px;
        height: 95px;
    }
`;

const AgeDecimal = styled(AgeText)`
    font-size: 105px;
    line-height: 100px;
    height: 100px;
    margin-top: 5px;

    @media (max-width: 762px) {
        font-size: 70px;
        line-height: 60px;
        height: 60px;
        margin-top: 0px;
    }
`;

const AgeLabel = styled.div`
    color: white;
    font-size: 61px;
    line-height: 61px;
    font-family: 'Satoshi-Medium';
    margin-top: -10px;

    @media (max-width: 762px) {
        font-size: 30px;
        line-height: 20px;
        height: 20px;
        margin-bottom: 10px;
    }
`;

const FinalAgeWrapper = styled(Column)`
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 25px;
    top: 0px;
    align-items: center;
    justify-content: center;
    z-index: 3;
    transform: scale(0.8);
`;


const ResultDateWrapper = styled(Row)`
    color: white;
    font-size: 14px;
    gap: 14px;
    align-items: center;
    img {
        height: 20px;
    }
`;

const AgeProgressWrapper = styled(Row)`
    color: white;
    font-size: 28px;
    margin-top: 10px;
    gap: 14px;
    align-items: center;
    img {
        height:36px;
    }
`;

const ChronologicalAgeWrapper = styled(Row)`
    background: rgba(255, 255, 255, 0.08);
    padding: 13px 26px;
    color: white;
    font-size: 20px;
    gap: 11px;
    border-radius: 100px;
    align-items: center;


    img {
        height: 20px;
    }

    @media (max-width: 1100px) {
        font-size: 16px;
    }
`;


const AgeProgress = ({ children }) => {

    const lower = children < 0;

    if (!children) return <div style={{ height: 40 }} />

    return <AgeProgressWrapper>
        <img src={lower ? progressGoodDown : progressBadUp} alt=''/>
        {children}
    </AgeProgressWrapper>

}

const ChronologicalAge = ({ children }) => {

    return <ChronologicalAgeWrapper>
        <img src={clockIcon} alt=''/>
        {children}
    </ChronologicalAgeWrapper>

}

const ResultsDate = ({ children }) => {

    return <ResultDateWrapper>
        <img src={calendar} alt=''/>
        {children}
    </ResultDateWrapper>

}

const ContentsOverlay = styled(Row)`
    height: 100%;
    position: relative;
    overflow: hidden;

    @media (max-width: 1100px) {
        height: 80vh;
    }
`;

const AgeInfoFulfilledWrapper = styled(Column)`
    height: 100%;
    flex: 1;


    @media (min-width: 1400px) {
        align-items: center;
    }

`;

export const AgeInfoFulfilled = ({ hideChronologicalAge }) => {

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])
    
    const ageData = useSelector((state) => ({ results: state.user.userData.health_results, kits: state.user.userData.kits }));
    const lastAgeData = ageData.results[0];
    const kitData = ageData.kits.find((k) => k.id === lastAgeData.kit_id)

    return <AgeInfoFulfilledWrapper>
    <ContentsOverlay>
       <Contents>
            {hideChronologicalAge ? <div style={{ height: 200 }} /> : <ChronologicalAge >Chronological Age: {lastAgeData.chronological_age}</ChronologicalAge>}
            <FinalAgeWrapper>
                <FinalAge age={lastAgeData.biological_age} />
            </FinalAgeWrapper>
            <ResultsDate>{moment(kitData.registered_at).format('MMMM D, YYYY')} Results</ResultsDate>
        </Contents>
        <AgeBgEllipse src={ageBgEllipse} />
    </ContentsOverlay>
    <div style={{ flex: 1 }} />

    </AgeInfoFulfilledWrapper>
}

