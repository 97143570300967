/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import { Column, FlexRow, Row } from '../components/defaults';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserData } from '../redux/reducer/user';
import loadingIcon from '../images/grid.svg';
import arrow from '../images/arrow-navy.svg';
import shareIcon from '../images/share.svg';
import { NotificationManager } from 'react-notifications';
import { AgeInfoFulfilled } from '../components/age-message';
import moment from 'moment';
import { AgeGraph } from '../components/line-graph';
import legendBlueLine from '../images/legend-blueline.svg';
import legendNavyBlueLine from '../images/legend-navyblueline.svg';
import legendYellowCircle from '../images/legend-yellowcircle.svg';
import legendPinkLine from '../images/legend-pinkline.svg';
import bodyPurple from '../images/body-purple.svg';
import useAuth from '../utils/useAuth';
import { convertYearsToYearsAndMonths } from '../utils/date';
import { getToken } from '../utils/age';

const breakpoint = '1000px';

const Wrapper = styled(Column)`
    background: #FDFDFF;
`;  

const TopContents = styled(Column)`
    background: white;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    padding-top: 60px;
    padding-right: 50px;
    padding-left: 50px;

    @media (max-width: ${breakpoint}) {
        padding-top: 30px;
        padding-right: 30px;
        padding-left: 30px;
    }
`;

const Contents = styled(FlexRow)`
    padding-right: 50px;
    padding-left: 50px;
    gap: 40px;
    padding-top: 30px;
    padding-bottom: 40px;
    background: rgba(253, 253, 255, 1);
    border-top: 1px solid rgba(231, 231, 233, 1);


    @media (max-width: ${breakpoint}) {
        padding-top: 30px;
        padding-right: 30px;
        padding-left: 30px;
    }
`;

const LoadingScreenWrapper = styled(Column)`
    align-items: center;
    justify-content: center;
    height: 80vh;
`;

const LoadingIcon = styled.img`
    height: 40px;
`;

const LoadingScreen = () => {
    return <LoadingScreenWrapper>
        <LoadingIcon src={loadingIcon} />
    </LoadingScreenWrapper>
}

const TopPanelWrapper = styled(FlexRow)`
    background: rgba(10, 19, 48, 1);
    padding-left: 72px;
    padding-right: 72px;
    align-items: center;
    box-sizing: border-box;

    @media (max-width: 1100px) {
        padding-top: 70px;
        padding-bottom: 70px;
        padding-left: 0px;
        padding-right: 0px;
        gap: 50px;
    }

    @media (min-width: 1100px) {
        gap: 150px;
        height: 80vh;
    }
`;

const ButtonsWrapper = styled(FlexRow)`
    margin-top: 40px;
    gap: 15px;

    @media (max-width: 1100px) {
        justify-content: center;
        align-items: center;
    }

`;

const TopPanelText = styled.div`
    color: white;
    font-family: 'Satoshi-Regular';
    font-size: 35px;
    line-height: 40px;
    
    b {
        color: rgba(30, 220, 255, 1);
        font-weight: 800;
    }

    @media (max-width: 762px) {
        font-size: 30px;
        line-height: 40px;
    }

    @media (max-width: 1100px) {
        align-items: center;
    }
`;


const TopPanelButton = styled(Row)`
    cursor: pointer;
    white-space: nowrap;
    background: ${props => props.background ? props.background : 'transparent'};
    border: 1px solid white;
    border-radius: 100px;
    padding: 13px 24px;
    color: ${ props => props.color ? props.color : 'white'};
    cursor: pointer;
    align-items: center;

    img {
        margin-left: 10px;
        height: 15px;
    }
`; 

const Divider = styled.div`
    width: 100%;
    height: 1px;
    background: rgba(255,255,255,0.4);

    @media (min-width: 1100px) {
        display: none;
    }
`;

const Background = styled.div`
    background: rgba(10, 19, 48, 1);
`;

const LeftColumn = styled(Column)`
    flex: 1;


    @media (min-width: 1400px) {
        align-items: flex-end;
        flex: 0.7;
    }

`;


const TopPanel = () => {
    
    const ageData = useSelector((state) => state.user.userData.health_results);
    const lastAgeData = ageData ? ageData[0] : null;
    const difference = Math.abs(lastAgeData?.biological_age - lastAgeData?.chronological_age).toFixed(1);
    const [years, months] = difference.split('.')
    const lower = lastAgeData?.biological_age < lastAgeData?.chronological_age

    const share = async () => {
        const token = await getToken({ years, months, lower })
        const link = `${process.env.REACT_APP_DOMAIN}/share?age=${token}`
        await navigator.clipboard.writeText(link);
        NotificationManager.success('Link copied!')
    }

    return <TopPanelWrapper  breakpoint='1100px'>
        {/*<ShareBanner visible={shareVisible} setVisible={setShareVisible}/>*/}
        <LeftColumn>
            <TopPanelText>Your TruMe age is <b>{years} {years === 1 ? 'year' : 'years'}</b></TopPanelText>
            {months != 0 ? <TopPanelText><b>and {months} {months === 1 ? 'month' : 'months'}</b> {lower ? 'lower' : 'higher'} than</TopPanelText> :
            <TopPanelText>{lower ? 'lower' : 'higher'} than</TopPanelText>}
            <TopPanelText>your chronological age.</TopPanelText>
            <ButtonsWrapper>
                <TopPanelButton onClick={() => window.open('https://trumelabs.com/solutions/truage-explorer-test','_blank')} background='white' color='rgba(5, 31, 115, 1)'>Order New Test<img src={arrow} /></TopPanelButton>
                <TopPanelButton onClick={share}>Share Results<img src={shareIcon} /></TopPanelButton>
            </ButtonsWrapper>
        </LeftColumn>
        <Divider />
        <AgeInfoFulfilled />
    </TopPanelWrapper>
}

const Months = styled(Row)`
`;

const Title = styled.div`
    color: rgba(5, 31, 115, 1);
    font-family: 'Satoshi-Bold';
    font-size: 32px;
    margin-bottom: 19px;
`;

const Subtitle = styled.div`
    color: rgba(91, 99, 125, 1);
    font-family: 'Satoshi-Regular';
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 36px;

    @media (max-width: ${breakpoint}) {
        font-size: 18px;
        line-height: 24px;
    }

    @media (min-width: ${breakpoint}) {
        max-width: 50%
    }
`;

const InfoWrapper = styled(Row)`
    height: 140px;
    position: relative;
    border-radius: 16px;
    overflow: hidden;
    gap: 30px;
    margin-left: 188px;
    min-width: 308px;

    @media (max-width: ${breakpoint}) {
        margin-left: initial;
        margin-bottom: 30px;
        height: 100px;
    }
`;

const InfoText = styled.div`
    color: white;
    font-size: 25px;
    align-self: flex-start;
    z-index: 1;
    padding-top: 17px;
    padding-left: 23px;
    flex: 1;
`;

const PlayIcon = styled.img`
    height: 50px;
    align-self: flex-end;
    z-index: 1;
    margin-bottom: 8px;
    margin-right: 17px;
`;

const InfoBackground = styled.img`
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
`;

const Info = () => {
    return <InfoWrapper>
        {/*<InfoText>Understanding your Results</InfoText>
        <PlayIcon src={playIcon}/>
        <InfoBackground src={understandingYourResults} />*/}
    </InfoWrapper>

}

const MonthButton = styled.div`
    background: ${props => props.selected ? 'rgba(245, 246, 250, 1)' : 'transparent'};
    color: ${props => props.selected ? 'rgba(79, 89, 240, 1)' : 'rgba(5, 31, 115, 1)'};
    font-size: 16px;
    padding: 7px 20px;
    border-radius: 100px;
    white-space: nowrap;
    cursor: ${ props => props.selected ? 'initial' : 'pointer' };
`;

const MonthTabsWrapper = styled(Row)`
    gap: 10px;
    overflow-x: scroll;
    padding-bottom: 15px;
    margin-bottom: 20px;
`;

const MonthTabs = ({ months, setMonth=()=>{}, selected }) => {

    return <MonthTabsWrapper>
        <MonthButton selected={selected==='All Time'} onClick={() => setMonth('All Time')}>All Time</MonthButton>
        {months.map((date) => <MonthButton 
            selected={selected===date}
            onClick={() => setMonth(date)}>{moment(date).format(`MMMM' YY`)}</MonthButton>)}
    </MonthTabsWrapper>
}

const AdditionalDetails = styled(Column)`
    min-width: 400px;
    gap: 24px;

    @media (max-width: 500px) {
        min-width: 100%;
    }
`;

const DetailsBoxWrapper = styled(Column)`
    border: 1px solid rgba(226, 226, 255, 1);
    border-radius: 16px;
    overflow: hidden;
`;

const DetailsBoxTitle = styled.div`
    color: rgba(5, 31, 115, 1);
    font-size: 20px;
    margin-left: 32px;
    margin-top: 30px;
    margin-bottom: 30px;
`;

const DetailsBoxContent = styled(Column)`
    padding: 40px;
`;


const DetailsBox = ({ title, children, style = {}}) => {
    return <DetailsBoxWrapper style={style}>
        <Row style={{ marginBottom: 20, zIndex: 10, background: 'white', boxShadow: '0px 20px 24px 0px rgba(79, 89, 240, 0.02)'}}>
            <DetailsBoxTitle>{title}</DetailsBoxTitle>
        </Row>
        <DetailsBoxContent style={{ padding: 16, paddingTop: 0, background: 'transparent' }}>
            {children}
        </DetailsBoxContent>
    </DetailsBoxWrapper>
}


const LegendWrapper = styled.div`
    gap: 20px;
    white-space: nowrap;
    align-self: center;
    margin-top: 20px;
    margin-right: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;

    @media (max-width: 1100px) {
        gap: 10px;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
    }
`;

const LegendItemWrapper = styled(Row)`
    gap: 10px;
    color: rgba(5, 31, 115, 1);
    font-size: 16px;
    align-items: center;

    img {
        height: 20px;
    }

    @media (max-width: ${breakpoint}) {
        font-size: 16px;
        img {
            height: 15px;
        }
    }
`;

const LegendItem = ({icon, children }) => {
    return <LegendItemWrapper>
        <img src={icon} />
        {children}
    </LegendItemWrapper>
}

const Legend = () => {

    return <LegendWrapper breakpoint='1000px'>
        <LegendItem icon={legendBlueLine}>Chron. Age</LegendItem>
        <LegendItem icon={legendYellowCircle}>Measurement</LegendItem>
        <LegendItem icon={legendNavyBlueLine}>Younger</LegendItem>
        <LegendItem icon={legendPinkLine}>Older</LegendItem>
    </LegendWrapper>
    

}

const LearnMoreWrapper = styled(FlexRow)`
    padding: 16px;
    padding-left: 30px;
    background: #F4F3FE;
    border-radius: 10px;
    font-size: 20px;
    color: rgba(5, 31, 115, 1);
    align-items: center;
    box-sizing: border-box;
    margin-top: 60px;

    #button {
        display: row;
        gap: 6px;
        background: white;
        padding: 13px 24px;
        border-radius: 100px;
        cursor: pointer;
    }

    @media (max-width: ${breakpoint}) {
        gap: 15px;

        #button {
            align-self: stretch;
            justify-content: center;
        }
    }
`;

const LearnMore = () => {

    return <LearnMoreWrapper breakpoint={breakpoint}>
        {/*<div style={{ flex: 1 }} >Surprised by your results?</div>
        <Row id='button'>
            Learn More
            <img src={navyArrow} alt='' />
        </Row>*/}
    </LearnMoreWrapper>

}

const DetailsContent = styled(FlexRow)`
    margin: 20px;
    margin-bottom: 5px;
    gap: 30px;
`;

const AgeSmallWrapper = styled(Row)`
    color: rgba(79, 89, 240, 1);
    align-items: center;
    justify-content: center;
    font-family: 'Satoshi-Regular';
    height: 70px;
    margin-left: -5px;
    overflow: hidden;
`;

const AgeSmallYears = styled.div`
    font-size: 60px;  
    font-weight: 400;
    margin-left: 5px;
`;

const Measurement = styled.div`
    color: rgba(79, 89, 240, 1);
    font-family: 'Satoshi-Regular';
    margin-left: -5px;
    font-size: 60px;
    overflow: hidden;
    margin-top: -6px;
`;

const AgeSmallMonths = styled.div`
    font-size: 31px;
    font-weight: 400;
`;

const AgeSmallTag = styled.div`
    font-size: 18px;
    font-weight: 400;
    margin-top: -8px;
`;

const AgeSmall = ({ age }) => {

    const [years,months] = age?.toFixed(2).toString()?.split('.')

    return <AgeSmallWrapper>
        <AgeSmallYears>{years}</AgeSmallYears>
        <Column>
            <AgeSmallMonths>.{months || '0'}</AgeSmallMonths>
            <AgeSmallTag>years</AgeSmallTag>
        </Column>
    </AgeSmallWrapper>

}

const DetailsTitle = styled.div`
    color: rgba(91, 99, 125, 1);
    font-size: 16px;
`;

const DetailsColumn = styled(Column)`
    align-items: flex-start;
`;  

const DetailsWrapper = ({ selected }) => {

    const data = useSelector((s) => s.user.userData.health_results);
    const ageDataIndex = data.findIndex((s) => s.registered_at === selected);
    const ageData = data[ageDataIndex];
    const previous = ageDataIndex > 0 ? data[ageDataIndex - 1] : null;
    const difference = ageData?.biological_age - previous?.biological_age;

    let years, months;
    if (difference) {
        [years, months] = difference.toFixed(1).toString().split('.');
    }

    return <DetailsContent breakpoint='1000px'>
        <DetailsColumn>
            <DetailsTitle>TruMe Age</DetailsTitle>
            {ageData?.biological_age && <AgeSmall age={ageData?.biological_age} />}
        </DetailsColumn>
        {previous &&<DetailsColumn>
            <DetailsTitle>Measurement</DetailsTitle>
            <Measurement>{years > 0 ? '+' : ''}{years}y {months}m</Measurement>
        </DetailsColumn>}
    </DetailsContent>
}

const MainDetails = ({ selected }) => {
    
    return <DetailsBox style={{ flex: 1 }} 
        title={selected === 'All Time' ? 'All Time' : `${moment(selected).format(`MMMM' YY`)} results`}>
        {selected !== 'All Time' && <DetailsWrapper selected={selected} />}
        <AgeGraph focusPoint={selected === 'All Time' ? null : selected} textColor='black'/>
        <Legend />
        <LearnMore />
    </DetailsBox>

}

const StatsWrapper = styled(Row)`
    padding: 16px;
    background: rgba(249, 249, 249, 1);
    border-radius: 8px;
    align-items: center;
    
    img {
        height: 40px;
        width: 40px;
        margin-right: 16px;
    }
`;

const StatsTitle = styled.div`
    font-size: 16px;
    flex: 1;
    color: rgba(91, 99, 125, 1);
`;

const StatsValue = styled.div`
    color: rgba(79, 89, 240, 1);
    font-size: 24px;
`;

const Stats = ({ icon, title, children}) => {

    return <StatsWrapper>
        <img src={icon} />
        <StatsTitle>{title}</StatsTitle>
        <StatsValue>{children}</StatsValue>
    </StatsWrapper>

}   

const SidebarDetails = ({ title, trumeAge }) => {
    const [year,month] = trumeAge

    return <DetailsBox title={title}>
        <Stats icon={bodyPurple} title='TruMe Age'>{year}y {month !== 0 && `${month}m`}</Stats>
    </DetailsBox>
}

export const AgeDashboard = () => {

    const dispatch = useDispatch();
    const [ forceLoading, setForceLoading ] = useState(true)
    const { currentUser, isLoading } = useAuth()
    const [month, setMonth] = useState('All Time');
    const { userData, loading } = useSelector((s) => s.user)
    const dataAvailable = !loading && Object.keys(userData).length > 0

    useEffect(() => {
        //console.log('data available', dataAvailable, currentUser)
        if (currentUser && !dataAvailable) {
            dispatch(fetchUserData())
        };
        if (dataAvailable) { setForceLoading(false) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser, dataAvailable])

    const ageData = useSelector((state) => state.user.userData.health_results);
    const months = ageData?.map(({ registered_at }) => registered_at);
    

    const reverseMonths = ageData ? [...ageData].splice(0, 3) : [];


    const extraDetails = reverseMonths.map((m) => {
        let [years, months] = convertYearsToYearsAndMonths(m.biological_age)
        
        return {
            title: moment(m.registered_at).format(`MMMM' DD, YYYY`),
            trumeAge: [years, months]
        }
    })

    if (!ageData?.length) return <div />

    return <Wrapper>
        {forceLoading || isLoading ? <LoadingScreen /> : <>
        <TopPanel/>
        <Background>
            <TopContents>    
                <FlexRow breakpoint={breakpoint}>
                    <Column>
                        <Title>Your Age Progress</Title>
                        <Subtitle style={{ flex: 1 }}>Review your TruMe Age and discover patterns in your aging. Use these insights to guide your health and wellness decisions.</Subtitle>
                        <div style={{ flex: 1}} />
                        <Months></Months>
                    </Column>
                    {/*<Info />*/}
                </FlexRow>
                <MonthTabs setMonth={setMonth} selected={month} months={months.reverse()} />
            </TopContents>
            <Contents>
                <MainDetails selected={month}/>
                {month === 'All Time' && <AdditionalDetails>
                    {extraDetails.map((e) => <SidebarDetails {...e} />)}
                </AdditionalDetails>}
            </Contents>
        
        </Background>
        </>}
    </Wrapper>
}