/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { Container, Input } from '../../components/defaults';
import { KitHeader } from '../../components/kit-header';
import mainbg from '../../images/main-bg.svg';
import sphere from '../../images/sphere.svg';
import topCurve from '../../images/top-curve.svg';
import api from '../../utils/api'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { 
    Contents, 
    ErrorComponent, 
    FormBox, 
    FormContents, 
    MainBg, 
    Sphere, 
    SubmitButton, 
    ThisTitle, 
    TopCurve 
} from '../../components/auth-defaults';
import useAuth from '../../utils/useAuth';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { clear } from '../../redux/reducer/registration';


const Message = styled.div`
    background: #F4F3FE;
    color: #051F73;
    font-family: 'Satoshi-Medium';
    font-size: 18px;
    padding: 16px 24px;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: -10px;
`;



export const BarcodeInputBox = ({onClickGoToDashboard, ...props}) => {

    const [barcode, setBarcode] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { currentUser } = useAuth();
    const ref = useRef();
    const dispatch = useDispatch();


    useEffect(() => {
        ref?.current?.focus();
    },  [])

    const submit = async (forceBarcode) => {
        if (loading || (!barcode && !forceBarcode)) return;
        const thisBarcode = forceBarcode ? forceBarcode : barcode;
        setLoading(true)
        setError('');

        try {
            const result = await api.post('/kit', { barcode: thisBarcode });
            if (!result.data?.success) throw new Error('Error')
            
            else {
                const pageNumber = currentUser ? '3' : '1';
                dispatch(clear());
                navigate(`/registration/${pageNumber}?kit=`+ thisBarcode)
            }
        } catch (err) {
            //console.log(err)
            setError('Kit number was not found');
        }
        setLoading(false);
    }
    
    return <FormBox {...props}>
        <FormContents>
            <ThisTitle>Let's start by entering your kit number</ThisTitle>
            <Input
                ref={ref}
                submit={() => submit()} 
                value={barcode} 
                onChange={(e) => setBarcode(e.target.value)} 
                label='Kit Number' 
                style={{ marginTop: 32 }} />
            <div style={{ flex: 1 }} />
            <ErrorComponent>{error}</ErrorComponent>
            <SubmitButton loading={loading} disabled={loading || !barcode} onClick={() => submit()}>Submit</SubmitButton>
        </FormContents>
        <TopCurve src={topCurve}/>
    </FormBox>
}




export const BarcodeInput = () => {

    
    return <Container style={{ background: '#4F59F0'}}>
        <KitHeader dark={true} />
        <Contents> 
            <BarcodeInputBox />
            <MainBg src={mainbg}/>
            <Sphere src={sphere}/> 
        </Contents>
        
    </Container>    
}