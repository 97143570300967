import styled from "styled-components"
import { RegistrationForm } from "./template";

const yesNoOptions = {
    options: [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
        
    ],
    noDropdown: true,
    optionsGridStyle: {
        gridAutoFlow: 'column',
        gridTemplateRows: '1fr',
        gridTemplateColumns: 'repeat(2, min-content)'
    }
}


let fields = [
    {
        label: 'Have you been diagnosed with cancer?',
        id: 'has_cancer',
        ...yesNoOptions
    },
    {
        label: 'Have you been diagnosed with diabetes?',
        id: 'has_diabetes',
        ...yesNoOptions
    },
    {
        label: 'Are you currently taking prescription medications?',
        id: 'medicine',
        ...yesNoOptions,
        nestedQuestion: {
            condition: 'Yes',
            label: 'What medications are you taking?',
            id: 'medicine_consumption',
            disableTrim: true
        },
    },
    {
        label: 'Do you take supplements?',
        id: 'supplements',
        ...yesNoOptions,
        nestedQuestion: {
            condition: 'Yes',
            label: 'What supplements are you taking?',
            id: 'supplement_consumption',
            disableTrim: true
        },
    },

]

const requiredFields = [
    'supplements',
    'medicine',
    'has_diabetes',
    'has_cancer',
]

const validations = {
    'supplement_consumption': (supplementConsumption, values) => {
        if (values?.supplements?.value === 'Yes' && !supplementConsumption) throw new Error('this field is required')
    },
    'medicine_consumption': (medicineConsumption, values) => {
        if (values?.medicine?.value === 'Yes' && !medicineConsumption) throw new Error('this field is required')
    }
}

const Fields = styled.div`
    display: grid;
    row-gap: 20px;
    column-gap: 20px;
    margin-top: 30px;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    grid-template-areas: 
        "has_cancer"
        "has_diabetes"
        "medicine"
        "supplements"
`;

export const RegistrationForm05 = () => {

    return <RegistrationForm
        subtitle='Medical History'
        title='Details about your medical condition.'
        Fields={Fields}
        fields={fields}
        progress={70}
        nextRoute='/registration/6'
        requiredFields={requiredFields}
        validations={validations}
    />
    
    
}